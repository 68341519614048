<template>
  <div
      class="flex flex-col items-center justify-between rounded-md mx-2 hover:bg-white h-full cursor-pointer pb-2 border border-transparent hover:border-a-neutral-dim hover:shadow-md"
  >
    <!-- Card Content -->
    <div class="flex flex-col w-full h-full ">
      <!-- Image -->
      <NuxtImg
          :src="islamicCenter.cover ?? islamicCenter.logo"
          alt=""
          class="object-cover h-40 rounded-t-md"
      />
      <!-- Image Ends -->
      <div class="flex flex-1 flex-col w-full p-2">
        <!-- Title -->
        <div class="flex flex-row items-center">
          <div class="flex flex-row">
            <NuxtImg
                v-if="islamicCenter.logo"
                :src="islamicCenter.logo"
                class="w-20 h-20 object-cover mr-1 rounded"
            />
            <Icon
                v-else
                name="material-symbols:prayer-times"
                class="text-a-neutral-light text-4xl mr-1"
            />
          </div>
          <p class="font-semibold m-1">
            {{ excerptedTitle }}
          </p>
        </div>
        <!-- Title Ends -->
        <!-- Address -->
        <div class="flex flex-row items-center my-0.5">
          <Icon
              name="mdi:map-marker"
              class="text-a-error-dark text-2xl mr-1"
          />
          <p class="text-sm">{{ islamicCenter.street_address }}, {{ islamicCenter.city }}, {{ islamicCenter.state }}
            {{ islamicCenter.zip }}</p>
        </div>
        <!-- Address Ends -->
      </div>
      <!-- Card Content Ends-->
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>

import type {HomeIslamicCenter} from "~/types";

const props = withDefaults(defineProps<{
  islamicCenter: HomeIslamicCenter
}>(), {});

const excerptedTitle = computed(() => {
  if (props.islamicCenter.name.length <= 70) {
    return props.islamicCenter.name
  }
  return (props.islamicCenter.name as string).slice(0, 70) + '...';
})
</script>

<style></style>
